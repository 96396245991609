import React from "react";
import Container from "../utility/Container";
import verve from "../../assets/img/verve.png";
import paystack from "../../assets/img/paystack.png";
//import foreignFlipGlobal from "../../assets/img/foreignFlipGlobal.svg";
import CTAButton from "../utility/CTAButton";

const Hero = () => {
  return (
    <div className="w-full">
      <Container className="flex flex-col lg:pt-[60px] relative justify-between items-center lg:flex-row">
        <div className="flex-1 py-10 space-y-4 pt-[70px] md:py-[100px] lg:py-[40px]">
          <h1 className="leading-tight text-primary leading-11 font-bold text-[38px] md:text-[48px] md:leading-[58px] md:text-left">
          Need To Sell Your House Fast in Columbus Ohio?.
          </h1>
          <p className="md:text-left mt-4 leading-[29px] mb-5 font-normal text-base md:text-2xl text-[#3A3A3A]">
          We buy houses in Columbus Ohio in any condition or location.
          </p>
          <CTAButton className="!my-5" />
          <div>
            <p className="text-sm md:text-base text-[#3A3A3A]">Secured By</p>
            <div className="flex items-center justify-start mt-0 gap-2">
              <img
                src={paystack}
                className="w-1/2 md:w-full max-w-[208.8px]"
                alt="Amazing Properties is a registered LLC"
              />
              <img
                src={verve}
                className="w-1/2 md:w-full max-w-[171px]"
                alt="Amazing Properties"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-start lg:self-start lg:justify-self-end relative lg:-right-[100px] flex-1">
          <img
            src={paystack}
            className="w-full max-w-[500px] z-10 my-8 lg:scale-105 lg:ml-auto lg:my-0"
            alt="Spectrum is a trusted africa crypto and giftcard platform"
          />
        </div>
        {/* Rememner to display component(gradient) and set body overflow to hidden */}
        <div
          className="absolute w-[800px] h-[800px] hidden rounded-[301px] -bottom-20 -right-[300px]"
          style={{
            background:
              "radial-gradient(44.85% 44.85% at 50% 50%, rgba(255, 202, 102, 0.4) 18.46%, rgba(255, 175, 0, 0) 100%)",
          }}
        />
      </Container>
    </div>
  );
};

export default Hero;
